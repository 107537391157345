import React, { forwardRef, useState, useEffect } from 'react';

import { TextArea } from 'grommet';
import styled from 'styled-components';
import { layout } from 'styled-system';

import { Popper } from 'components/popper/popper';
import { Text } from 'components/text/text';
import { mergeRefs } from 'utils/fns';

import { InputPlaceholder } from './input-placeholder';
import { inputStyle, InputWrapper } from './styles';

import type { PlaceholderProps } from './input-placeholder';
import type { InputStylesProps } from './styles';
import type { ControllerRenderProps } from 'react-hook-form';

const TextAreaStyled = styled(TextArea)`
    ${inputStyle};
    resize: none;
    padding: 20px 10px;
    ${layout};
    border: none;
`;

const TextAreaWrapper = styled.div<{ disabled: boolean; error: any }>`
    background-color: ${({ disabled }) =>
        disabled ? 'var(--color-light-5)' : 'var(--color-white)'};
    border-style: solid;
    border-width: ${({ theme }) => theme.global.control.border.width};
    border-radius: ${({ theme }) => theme.global.control.border.radius};
    border-color: ${({ error }) =>
        error ? 'var(--color-red-500)' : 'var(--color-light-1)'};
    :hover {
        border-color: var(--color-midnight-500);
    }
    :focus-within {
        border-color: var(--color-blue-500);
    }
`;

type ComponentProps = JSX.IntrinsicElements['textarea'] &
    Pick<PlaceholderProps, 'hidePlaceholder' | 'required'>;

export const TextAreaInputComponent = forwardRef(
    (
        {
            hidePlaceholder,
            gridArea,
            className,
            required,
            ...rest
        }: ComponentProps & InputStylesProps & Partial<ControllerRenderProps>,

        ref: any
    ) => {
        const [isPopperOpen, setIsPopperOpen] = useState(false);
        const [referenceElement, setReferenceElement] = useState(null);
        const [innerRefElement, setInnerRefElement] = useState(null);

        useEffect(() => {
            if (rest.error && document.activeElement === innerRefElement) {
                // if it has focus we show the error
                setIsPopperOpen(true);
            }
        }, [innerRefElement, rest.error]);

        return (
            <InputWrapper
                className={className}
                ref={setReferenceElement}
                css={{ gridArea }}
            >
                <TextAreaWrapper disabled={rest.disabled} error={rest.error}>
                    <TextAreaStyled
                        data-testid={`${rest?.id || rest?.name}-textarea-input`}
                        ref={mergeRefs(ref, setInnerRefElement)}
                        id={rest?.id || rest.name}
                        {...rest}
                    />
                    {!hidePlaceholder && (
                        <InputPlaceholder
                            name={rest.name}
                            placeholder={rest.placeholder}
                            hidePlaceholder={hidePlaceholder}
                            required={required}
                        />
                    )}
                    {rest.maxLength && (
                        <Text
                            color="placeholder"
                            textAlign="right"
                            display="block"
                            fontSize={10}
                            pr={10}
                        >
                            {rest.value?.length ?? 0} / {rest.maxLength}
                        </Text>
                    )}
                </TextAreaWrapper>

                {rest.error && (
                    <Popper
                        referenceElement={referenceElement}
                        isOpen={isPopperOpen}
                        variant="error"
                    >
                        {rest.error.message}
                    </Popper>
                )}
            </InputWrapper>
        );
    }
);
