import React, { forwardRef, useEffect, useState } from 'react';

import { Popper } from 'components/popper/popper';
import { getInputVariant } from 'constants/formElements';
import { isEmpty, formatMoney, mergeRefs } from 'utils';
import { usePrevious } from 'utils/use-previous';

import { InputPlaceholder } from '../input-placeholder';
import { Input, InputWrapper } from '../styles';

import type { PlaceholderProps } from '../input-placeholder';

export type MoneyInputProps = { integer?: boolean; error?: any } & Pick<
    PlaceholderProps,
    'hidePlaceholder'
> &
    Omit<JSX.IntrinsicElements['input'], 'width' | 'height' | 'size'>;

/**
 * @deprecated use CurrencyField from components/forms instead, or
 * CurrencyInput from '@nestoca/ui'
 */
export const MoneyInputComponent = forwardRef<any, MoneyInputProps>(
    (
        {
            onChange,
            value,
            hidePlaceholder,
            integer = false,
            gridArea,
            className,
            required,
            // We cant block min to use prev value
            // because if min is 2 digit number i.e. `21` user wont be allow
            // to start entering the first digit number `2` will be block and not show
            // min value should be validate in form this component is too dump to have business logic included
            max = Number.MAX_SAFE_INTEGER, // 9 007 199 254 740 991
            error,
            variant,
            ...rest
        }: any,
        ref: any
    ) => {
        const [myValue, setMyValue] = useState(+value);
        const prevMyValue = usePrevious(myValue);
        const [isPopperOpen, setIsPopperOpen] = useState(false);
        const [referenceElement, setReferenceElement] = useState(null);

        const [innerRefElement, setInnerRefElement] = useState(null);

        useEffect(() => {
            if (error && document.activeElement === innerRefElement) {
                // if it has focus we show the error
                setIsPopperOpen(true);
            }
        }, [innerRefElement, error]);

        useEffect(() => {
            const isOutOfRangeMax = +value > max;

            if (!isEmpty(value) && isOutOfRangeMax) {
                setMyValue(prevMyValue);
                return;
            }

            setMyValue(value);
        }, [value]);

        return (
            <InputWrapper
                className={className}
                css={{ gridArea }}
                ref={setReferenceElement}
            >
                <Input
                    data-testid={rest?.id || rest.name}
                    ref={mergeRefs(ref, setInnerRefElement)}
                    type="text"
                    id={rest?.id || rest.name}
                    error={error}
                    variant={error ? getInputVariant(error) : variant}
                    {...rest}
                    onChange={(e) => {
                        const fieldVal = e.currentTarget.value as string;

                        const floatVal = fieldVal.replace(/[^\d.-]/g, '');

                        const intValue = Math.trunc(+floatVal);

                        const newValue = integer ? intValue : floatVal;

                        e.currentTarget.value = String(newValue);
                        onChange(e);
                    }}
                    value={formatMoney(myValue)}
                    css={{ height: hidePlaceholder ? 40 : undefined }}
                    onFocus={() => error && setIsPopperOpen(true)}
                    onBlur={() => setIsPopperOpen(false)}
                />

                {error?.message && (
                    <Popper
                        referenceElement={referenceElement}
                        isOpen={isPopperOpen}
                        variant="error"
                    >
                        {error.message}
                    </Popper>
                )}

                {!hidePlaceholder && (
                    <InputPlaceholder
                        name={rest.name}
                        placeholder={rest.placeholder}
                        hidePlaceholder={hidePlaceholder}
                        required={required}
                    />
                )}
            </InputWrapper>
        );
    }
);
