import React from 'react';

import { useAuth } from 'providers/auth';

import type { TokenRole } from 'providers/auth/types';

function getDisplayName(Component: React.ComponentType<any>) {
    return Component.displayName || Component.name || 'Component';
}

/**
 * HOC that wraps a component and only renders it if the user is authorized by role
 * to view it
 *
 * @param role - Role to check against
 * @param WrappedComponent - React component to wrap
 *
 * @returns
 *
 * @example
 * const MyComponent = () => <div>My component</div>;
 * const MyAuthorizedComponent = authorized('broker')(MyComponent);
 */
export const authorized =
    (role: TokenRole | TokenRole[] /*, permission: Permission[] */) =>
    (WrappedComponent: React.ComponentType) => {
        const EnhancedComponent = (props: any) => {
            const { isAuthorized } = useAuth();

            if (!isAuthorized(role)) {
                return <div>You are not authorized to view this</div>;
            }

            return <WrappedComponent {...props} />;
        };

        EnhancedComponent.displayName = `authorized(${getDisplayName(
            WrappedComponent
        )})`;

        return EnhancedComponent;
    };

export default authorized;
