import { ErrorMessage as FormErrorMessage } from '@hookform/error-message';
import {
    ErrorMessage,
    FormElement,
    FormElementProps,
    HelperText,
    Select,
    SelectProps,
} from '@nestoca/ui';
import { Controller, useFormContext } from 'react-hook-form';

import { Optional } from 'types';

import { InputFieldProps } from './input-field';

export const SelectField = ({
    name,
    helperText,
    handleErrorMessage = true,
    isDisabled,
    isRequired,
    onChange,
    ...rest
}: Optional<SelectProps, 'onChange'> & FormElementProps & InputFieldProps) => {
    const {
        formState: { errors },
        control,
    } = useFormContext();

    return (
        <FormElement
            isInvalid={!!errors?.[name]}
            isDisabled={isDisabled}
            isRequired={isRequired}
        >
            <Controller
                control={control}
                name={name}
                render={({ field }) => (
                    <Select
                        id={name}
                        e2ePrefix={name}
                        {...field}
                        {...rest}
                        onChange={(value) => {
                            field.onChange(value);
                            onChange?.(value);
                        }}
                    />
                )}
            />

            {handleErrorMessage && (
                <FormErrorMessage
                    errors={errors}
                    name={name}
                    render={({ message }) => (
                        <ErrorMessage withIcon e2ePrefix={`${name}`}>
                            {message}
                        </ErrorMessage>
                    )}
                />
            )}

            {helperText && <HelperText>{helperText}</HelperText>}
        </FormElement>
    );
};
