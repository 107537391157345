import styled, { css } from 'styled-components';
import { variant } from 'styled-system';

import { BORDER_ERROR_COLORS } from 'constants/formElements';

import type { ResponsiveValue } from 'styled-system';

type Variant = 'warning' | 'error' | 'default';

export type InputStylesProps = {
    placeholderShown?: boolean;
    readOnly?: boolean;
    error?: any;
    disabled?: boolean;
    gridArea?: string | string[];
    variant?: ResponsiveValue<Variant>;
};

export const InputWrapper = styled.div`
    width: 100%;
    display: inline-flex;
    padding: 0px;
    flex-direction: column;
    vertical-align: top;
    position: relative;
    input:-webkit-autofill + div,
    textarea:-webkit-autofill + div {
        transform: translate(11px, 10px) scale(0.75);
    }
`;

export const inputStyle = css<InputStylesProps>`
    word-break: normal;
    font-size: 12px;
    box-sizing: border-box;
    outline: none;
    transition: all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);

    color: var(--color-gray-2);
    background-color: var(--color-white);
    border-style: solid;
    border-width: ${({ theme }) => theme.global.control.border.width};
    border-radius: ${({ theme }) => theme.global.control.border.radius};
    border-color: var(--color-light-1);

    ::placeholder,
    :placeholder-shown::placeholder {
        color: ${({ theme }) => theme.global.colors.placeholder};
        font-weight: 500;
    }

    :hover {
        border-color: ${({ error }) =>
            error?.message
                ? BORDER_ERROR_COLORS[error.type] || BORDER_ERROR_COLORS.default
                : 'var(--color-midnight-500)'};
    }

    :focus {
        border-color: ${({ error }) =>
            error?.message
                ? BORDER_ERROR_COLORS[error.type] || BORDER_ERROR_COLORS.default
                : 'var(--color-blue-500)'};
    }
    height: 60px;
    width: 100%;
    padding: 10px;

    @media only screen and (max-width: 992px) {
        min-width: unset;
    }

    ${variant({
        scale: 'textInputs',
        prop: 'variant',
    })}
`;
export const FakeInput = styled.div<InputStylesProps>`
    display: flex;
    align-items: center;
    cursor: not-allowed;
    ${inputStyle};
`;
export const Input = styled.input<InputStylesProps>`
    ${inputStyle};
`;
