import { useContext } from 'react';
import { useCallback } from 'react';

import { useAuth0 } from '@auth0/auth0-react';

import { UserContext } from 'providers/auth0';

import type { TokenRole } from './types';

const hasRole = (roles: TokenRole | TokenRole[], userRole: TokenRole) => {
    const _roles = Array.isArray(roles) ? roles : [roles];

    return _roles.some((role) => role === userRole);
};

/**
 * @deprecated
 *
 * Please use `useAuth0` instead to get user info
 * and use `useClient` to get client info
 *
 */
export const useAuth = () => {
    const { isAuthenticated, isLoading: isAuthenticating } = useAuth0();

    const userState = useContext(UserContext);

    const getRole = useCallback(
        () => userState.account?.role,
        [userState.account]
    );

    const isAuthorized = (roles: TokenRole | TokenRole[]) => {
        const userRole = getRole();

        if (!isAuthenticated) {
            return false;
        } else {
            return hasRole(roles, userRole);
        }
    };

    return {
        // Auth0
        isAuthenticating,
        // keep the same API as the old useAuth isAuthenticated
        isAuthenticated: () => isAuthenticated,
        isAuthorized,
        token: userState.token,
        // nesto logged in user account
        user: userState.account,
        userId: userState.account?.id,
        getRole,
        // nesto on behalf of client
        client: userState.client,
        clientId: userState.client?.id,
    };
};
