import { ReactNode, ChangeEvent } from 'react';

import { ErrorMessage as FormErrorMessage } from '@hookform/error-message';
import {
    ErrorMessage,
    FormElement,
    FormElementProps,
    HelperText,
    Input,
    InputGroup,
    InputProps,
} from '@nestoca/ui';
import { useFormContext } from 'react-hook-form';

export type InputFieldProps = {
    helperText?: ReactNode;
    handleErrorMessage?: boolean;
};

export const InputField = ({
    name,
    handleErrorMessage = true,
    helperText,
    isDisabled,
    isRequired,
    onChange,
    ...rest
}: InputProps & InputFieldProps & FormElementProps) => {
    const {
        formState: { errors },
        register,
    } = useFormContext();

    return (
        <FormElement
            isInvalid={!!errors?.[name]}
            isDisabled={isDisabled}
            isRequired={isRequired}
        >
            <InputGroup>
                <Input
                    id={name}
                    name={name}
                    e2ePrefix={name}
                    {...rest}
                    {...register(name, {
                        onChange: (e: ChangeEvent<HTMLInputElement>) => {
                            onChange && onChange(e);
                        },
                    })}
                />
            </InputGroup>

            {handleErrorMessage && (
                <FormErrorMessage
                    errors={errors}
                    name={name}
                    render={({ message }) => (
                        <ErrorMessage withIcon e2ePrefix={`${name}`}>
                            {message}
                        </ErrorMessage>
                    )}
                />
            )}

            {helperText && <HelperText>{helperText}</HelperText>}
        </FormElement>
    );
};
