import React, { ChangeEvent, useEffect } from 'react';
import { useCallback } from 'react';

import { ErrorMessage as FormErrorMessage } from '@hookform/error-message';
import { ErrorMessage, Flex, VStack, HelperText } from '@nestoca/ui';
import { useFormContext, useWatch } from 'react-hook-form';

import { numberNormalizer } from 'utils';


import { CurrencyField } from './currency-field';
import styles from './input.module.scss';
import { PercentageField } from './percentage-field';
import { calculatePercentage, calculateValueFromPercentage } from './utils';

type Props = {
    name: string;
    required?: boolean;
    isDisabled?: boolean;
    helperText?: string;
    // Number used as the base to calculate to and from the percentage value
    total: number;
};

export const AmountPercentageInput = ({
    name,
    required,
    isDisabled,
    helperText,
    total,
}: Props) => {
    const {
        setValue,
        control,
        formState: { errors },
    } = useFormContext();

    const percentageFieldRef = React.useRef<HTMLInputElement>(null);

    const amountValue = useWatch({ name: name, control: control });

    const setPercentageFromAmount = useCallback(
        (amount: number) => {
            const percentage = calculatePercentage({ value: amount, total });

            if (percentage) {
                percentageFieldRef.current.value = String(percentage);
            }
        },
        [total]
    );

    // Update percentage value when amount value changes
    useEffect(() => {
        if (!percentageFieldRef.current) {
            return;
        }

        // Only update percentage value if it's not focused
        // this is to avoid calling useEffect when the user is typing
        const isFocused = percentageFieldRef.current === document.activeElement;

        if (!isFocused) {
            setPercentageFromAmount(numberNormalizer(amountValue) || 0);
        }
    }, [amountValue, name, setPercentageFromAmount, total]);

    const onPercentageChange = (event: ChangeEvent<HTMLInputElement>) => {
        const percentage = +event.target.value || 0;

        const amount = calculateValueFromPercentage({ percentage, total });

        if (amount) {
            setValue(name, amount);
        }

        return event;
    };

    const onAmountChange = (event: ChangeEvent<HTMLInputElement>) => {
        const amount = numberNormalizer(event.target.value) || 0;

        setPercentageFromAmount(amount);

        return event;
    };

    return (
        <VStack
            className={styles['amount-percentage-field']}
            align="start"
            gap={1}
        >
            <Flex className={styles['field-container']}>
                <Flex className={styles['field']}>
                    <CurrencyField
                        id={name}
                        name={name}
                        required={required}
                        isDisabled={isDisabled}
                        handleErrorMessage={false}
                        onChange={onAmountChange}
                    />
                </Flex>
                <Flex className={styles['field']}>
                    <PercentageField
                        ref={percentageFieldRef}
                        isInvalid={!!errors?.[name]}
                        id={`${name}-percentage`}
                        name={`${name}-percentage`}
                        required={required}
                        isDisabled={isDisabled}
                        handleErrorMessage={false}
                        onChange={onPercentageChange}
                    />
                </Flex>
            </Flex>

            <FormErrorMessage
                errors={errors}
                name={name}
                render={({ message }) => (
                    <ErrorMessage withIcon e2ePrefix={name}>
                        {message}
                    </ErrorMessage>
                )}
            />

            {helperText && <HelperText>{helperText}</HelperText>}
        </VStack>
    );
};
