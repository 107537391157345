import React from 'react';

import { FakeInput, InputWrapper } from './styles';

type InputProps = {
    value?: any;
    hidePlaceholder?: boolean;
};

export const LTVInput = ({ value, hidePlaceholder = false }: InputProps) => (
    <InputWrapper>
        <FakeInput disabled css={{ height: hidePlaceholder ? 40 : undefined }}>
            {value || 0}
        </FakeInput>
    </InputWrapper>
);
