import React from 'react';

import css from '@styled-system/css';
import styled, { css as StyleCss } from 'styled-components';

import { Text } from 'components/text/text';

import type { InputStylesProps } from './styles';

const untouchedPlaceholderStyle = StyleCss`
    transform: translate(11px, 18px) scale(1);
`;

export type PlaceholderProps = {
    placeholder?: string;
    name?: string;
    className?: string;
    hidePlaceholder?: boolean;
    required?: boolean;
} & InputStylesProps;

const touchedPlaceholderStyle = StyleCss<PlaceholderProps>`
    transform: translate(11px, 10px)
        scale(${(props) => (props.hidePlaceholder ? 0 : 0.75)});
`;

const Placeholder = styled.div<InputStylesProps & PlaceholderProps>`
    top: 0;
    left: 0;
    position: absolute;
    pointer-events: none;
    padding: 0;
    color: ${({ theme }) => theme.global.colors.placeholder};
    font-size: 12px;

    overflow: hidden;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    transition: transform 100ms ease-in-out;
    transform-origin: top left;

    input:placeholder-shown ~ &,
    textarea:placeholder-shown ~ & {
        opacity: 0;
        ${untouchedPlaceholderStyle};
    }
    input:not(:placeholder-shown) ~ &,
    textarea:not(:placeholder-shown) ~ & {
        opacity: 1;
        ${touchedPlaceholderStyle};
    }
    ${(props) =>
        props.placeholderShown
            ? untouchedPlaceholderStyle
            : touchedPlaceholderStyle};
`;

export const InputPlaceholder = ({
    name,
    className,
    placeholder,
    hidePlaceholder = false,
    required,
    ...rest
}: Omit<PlaceholderProps, 'ref'>) => {
    if (!placeholder) {
        return null;
    }

    return (
        <>
            {required && (
                <Text
                    className="required-indicator"
                    css={css({
                        color: 'placeholder',
                        fontSize: 3,
                        position: 'absolute',
                        right: '3px',
                        top: '2px',
                        zIndex: 'default',
                    })}
                    tx="*"
                />
            )}
            <Placeholder
                className={className}
                data-testid={`input_label-${name}`}
                hidePlaceholder={hidePlaceholder}
                {...rest}
            >
                {placeholder}
            </Placeholder>
        </>
    );
};
