import React, { forwardRef } from 'react';

import { TextArea } from 'grommet';
import styled from 'styled-components';
import { layout } from 'styled-system';

import { InputPlaceholder } from './input-placeholder';
import { inputStyle, InputWrapper } from './styles';

import type { PlaceholderProps } from './input-placeholder';
import type { InputStylesProps } from './styles';
import type { ControllerRenderProps } from 'react-hook-form';

const MarkupTextAreaStyled = styled(TextArea)`
    ${inputStyle};
    resize: none;
    padding: 10px;
    border: 1px solid #eee;
    ${layout}

    , pre {
        -moz-tab-size: 2;
        -o-tab-size: 2;
        tab-size: 2;
    }
`;

type ComponentProps = JSX.IntrinsicElements['textarea'] &
    Pick<PlaceholderProps, 'hidePlaceholder' | 'required'>;

type Event = { event };

export const MarkupTextAreaInputComponent = forwardRef(
    (
        {
            hidePlaceholder,
            gridArea,
            className,
            required,
            event,
            ...rest
        }: ComponentProps &
            InputStylesProps &
            Partial<ControllerRenderProps> &
            Event,
        ref: any
    ) => {
        return (
            <InputWrapper className={className} css={{ gridArea }}>
                <MarkupTextAreaStyled
                    id={rest?.id || rest.name}
                    onBlur={event}
                    {...rest}
                    ref={ref}
                />
                {!hidePlaceholder && (
                    <InputPlaceholder
                        name={rest.name}
                        placeholder={rest.placeholder}
                        hidePlaceholder={hidePlaceholder}
                        required={required}
                    />
                )}
            </InputWrapper>
        );
    }
);
