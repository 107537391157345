// Returns a stringified number with the number of decimals specified
export const trimDecimals = (value: string, numDecimals: number = 2) => {
    // Preserves numbers, the negative sign, and the first dot character. Removes anything else
    const numericValue = value.replace(/[^-\d.]|(?<=^-?\d*\.\d*)\./g, '');

    // Truncate the numeric value to two decimal places
    const dotIndex = numericValue.indexOf('.');

    // If there is no decimal, return the original value
    if (dotIndex === -1) {
        return numericValue;
    }
    const truncatedValue = numericValue.slice(0, dotIndex + 1 + numDecimals);

    // Update the input value with the truncated number
    return truncatedValue;
};

// Clamps a string value between a min and a max
export const clamp = (
    value: string,
    options?: { min?: number; max?: number }
) => {
    const { min, max } = options || {};

    if (!options) {
        return value;
    }

    const valueAsNumber = Number(value);

    let clampedValue = valueAsNumber;

    // If the value is not a number, return the value
    if (isNaN(valueAsNumber)) {
        return value;
    }

    // If the max is less than the min, return the value
    if (max < min) {
        // eslint-disable-next-line no-console
        console.warn("The max value can't be less than the min value");

        return value;
    }

    if (min || min === 0) {
        clampedValue = Math.max(min, clampedValue);
    }

    if (max || max === 0) {
        clampedValue = Math.min(max, clampedValue);
    }

    // If the clamped value is the same as the original value, return the value
    if (clampedValue === valueAsNumber) {
        return value;
    }

    return String(clampedValue);
};

export const calculatePercentage = ({
    value,
    total,
}: {
    value: number;
    total: number;
}) => {
    if (total <= 0) {
        return 0;
    }

    const percentage = (value / total) * 100;

    const roundedPercentage = Math.round(percentage * 100) / 100;

    return roundedPercentage;
};

export const calculateValueFromPercentage = ({
    percentage,
    total,
}: {
    percentage: number;
    total: number;
}) => {
    const value = (percentage * total) / 100;

    const roundedValue = Math.round(value * 100) / 100;

    return roundedValue;
};
