import React, { forwardRef } from 'react';

import InputMask from 'react-input-mask';

import { TextInputComponent } from 'components/forms';


export const PostalCodeInput = forwardRef(
    (
        {
            value,
            onChange,
            placeholder,
            hidePlaceholder = false,
            error,
            countryCode = 'CA',
            ...rest
        }: any,

        ref: any
    ) => (
        <InputMask
            mask={
                countryCode === 'US'
                    ? '99999-9999'
                    : countryCode === 'CA'
                    ? 'a9a 9a9'
                    : '**********'
            }
            value={value || ''}
            onChange={onChange}
            maskChar={null}
            beforeMaskedValueChange={(newState, oldState, userInput) => {
                if (!newState) {
                    return { value: '', selection: { start: 0, end: 0 } };
                }

                let { value: newValue, selection } = newState;

                if (countryCode === 'US') {
                    let cursorPosition = selection ? selection.start : null;

                    // keep minus if entered by user
                    if (
                        newValue.endsWith('-') &&
                        userInput !== '-' &&
                        !value.endsWith('-')
                    ) {
                        if (cursorPosition === newValue.length) {
                            cursorPosition--;
                            selection = {
                                start: cursorPosition,
                                end: cursorPosition,
                            };
                        }
                        newValue = newValue.slice(0, -1);
                    }

                    return {
                        value: newValue,
                        selection,
                    };
                }

                return {
                    value: String(newValue).toUpperCase(),
                    selection,
                };
            }}
            {...rest}
        >
            {(inputProps) => (
                <TextInputComponent
                    {...inputProps}
                    placeholder={placeholder}
                    hidePlaceholder={hidePlaceholder}
                    error={error}
                    ref={ref}
                />
            )}
        </InputMask>
    )
);
