import React from 'react';

import SVG from 'components/SVG';

import styles from './checkbox.module.scss';

type Props = {
    className?: string;
    squareCheckbox?: boolean;
    isCheckboxChecked: boolean;
    color?: string;
};

export const CheckboxIcon = ({
    className,
    squareCheckbox,
    isCheckboxChecked,
    color = 'var(--color-primary)',
}: Props) => {
    const CheckboxChecked = (props) => (
        <SVG {...props}>
            {props.squareCheckbox ? (
                <>
                    <rect
                        rx={6}
                        ry={6}
                        width="100%"
                        height="100%"
                        transform="scale(0.75)"
                        transform-origin="50% 50%"
                        fill="var(--color-white)"
                        stroke={props.color}
                        strokeWidth={2}
                    />
                    <rect
                        rx={3}
                        ry={3}
                        width="100%"
                        height="100%"
                        transform="scale(0.45)"
                        transform-origin="50% 50%"
                        fill={props.color}
                    />
                </>
            ) : (
                <path d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" />
            )}
        </SVG>
    );

    const CheckboxUnchecked = (props) => (
        <SVG {...props}>
            {props.squareCheckbox ? (
                <rect
                    rx={6}
                    ry={6}
                    width="100%"
                    height="100%"
                    transform="scale(0.75)"
                    transform-origin="50% 50%"
                    fill="var(--color-white)"
                    stroke="var(--color-primary)"
                    strokeWidth={2}
                />
            ) : (
                <path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z" />
            )}
        </SVG>
    );

    return isCheckboxChecked ? (
        <CheckboxChecked
            aria-hidden="true"
            className={`${className || ''} ${styles.checkbox_icon}`}
            squareCheckbox={squareCheckbox}
            color={color}
        />
    ) : (
        <CheckboxUnchecked
            aria-hidden="true"
            className={`${className || ''} ${styles.checkbox_icon}`}
            squareCheckbox={squareCheckbox}
            color={color}
        />
    );
};
