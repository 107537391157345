import React, { forwardRef, useState, useEffect } from 'react';

import { Popper } from 'components/popper/popper';
import { getInputVariant } from 'constants/formElements';
import { mergeRefs } from 'utils/fns';

import { InputPlaceholder } from './input-placeholder';
import { Input, InputWrapper } from './styles';

import type { PlaceholderProps } from './input-placeholder';
import type { InputStylesProps } from './styles';

export type ComponentProps = JSX.IntrinsicElements['input'] &
    Pick<PlaceholderProps, 'hidePlaceholder' | 'required'>;

export type TextInputComponentProps = ComponentProps & InputStylesProps;

export const TextInputComponent = forwardRef(
    (
        {
            hidePlaceholder,
            gridArea,
            className,
            pattern,
            required,
            error,
            ...rest
        }: TextInputComponentProps,
        ref: any
    ) => {
        const [isPopperOpen, setIsPopperOpen] = useState(false);
        const [referenceElement, setReferenceElement] = useState(null);
        const [innerRefElement, setInnerRefElement] = useState(null);

        useEffect(() => {
            if (error && document.activeElement === innerRefElement) {
                // if it has focus we show the error
                setIsPopperOpen(true);
            }
        }, [innerRefElement, error]);

        return (
            <InputWrapper
                className={className}
                css={{ gridArea }}
                ref={setReferenceElement}
            >
                <Input
                    ref={mergeRefs(ref, setInnerRefElement)}
                    type="text"
                    id={rest?.id || rest.name}
                    data-testid={rest?.id || rest.name}
                    pattern={pattern}
                    variant={getInputVariant(error)}
                    {...(rest as any)}
                    error={{
                        ...error,
                        type:
                            // @ts-ignore
                            error?.type === 'warning' && required
                                ? 'error'
                                : // @ts-ignore
                                  error?.type,
                    }}
                    onFocus={() => error && setIsPopperOpen(true)}
                    onBlur={() => setIsPopperOpen(false)}
                    css={{ height: hidePlaceholder ? 40 : undefined }}
                    placeholder={(!hidePlaceholder && rest.placeholder) || ''}
                />

                {error && (
                    <Popper
                        referenceElement={referenceElement}
                        isOpen={isPopperOpen}
                        variant="error"
                    >
                        {error.message}
                    </Popper>
                )}
                {!hidePlaceholder && (
                    <InputPlaceholder
                        name={rest.name}
                        placeholder={rest.placeholder}
                        hidePlaceholder={hidePlaceholder}
                        required={required}
                    />
                )}
            </InputWrapper>
        );
    }
);
