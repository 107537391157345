import { ChangeEvent, useImperativeHandle, forwardRef } from 'react';

import { ErrorMessage as FormErrorMessage } from '@hookform/error-message';
import {
    ErrorMessage,
    FormElement,
    FormElementProps,
    HelperText,
    Input,
    InputAddonLeft,
    InputGroup,
    InputProps,
} from '@nestoca/ui';
import { useFormContext } from 'react-hook-form';

import { InputFieldProps } from './input-field';
import { clamp, trimDecimals } from './utils';

type PercentageFieldProps = InputProps &
    InputFieldProps &
    FormElementProps & {
        min?: number;
        max?: number;
    };

export const PercentageField = forwardRef<
    HTMLInputElement,
    PercentageFieldProps
>(function PercentageField(
    {
        name,
        handleErrorMessage = true,
        helperText,
        isDisabled,
        isRequired,
        isReadOnly,
        readOnly,
        onChange,
        min,
        max,
        ...rest
    }: PercentageFieldProps,
    ref
) {
    const {
        formState: { errors },
        register,
    } = useFormContext();

    const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        const inputValue = e.target.value;

        const clampedValue = clamp(inputValue, { min, max });

        const trimmedValue = trimDecimals(clampedValue);

        e.target.value = trimmedValue;

        onChange && onChange(e);
    };

    const { ref: registeredRef, ...registerField } = register(name, {
        onChange: onChangeHandler,
    });

    // Combine refs: https://react-hook-form.com/faqs#Howtosharerefusage
    useImperativeHandle(
        registeredRef,
        () => {
            // Ignoring the `current` property doesn't exist error because React Hook Form has poor typing here
            // https://github.com/react-hook-form/react-hook-form/blob/d116a151fbdfb79ca5eb7533bfef017ad7c80344/src/types/form.ts#L175
            // @ts-ignore
            const registeredRefInstance = registeredRef.current;

            return ref || registeredRefInstance;
        },
        [ref, registeredRef]
    );

    return (
        <FormElement
            isInvalid={!!errors?.[name]}
            isDisabled={isDisabled}
            isRequired={isRequired}
            isReadOnly={isReadOnly || readOnly}
        >
            <InputGroup>
                <InputAddonLeft>%</InputAddonLeft>
                <Input
                    // We are using type="text" because number inputs don't allow repositioning the cursor
                    // which causes cursor jumping when truncating decimals
                    type="text"
                    inputMode="decimal"
                    id={name}
                    name={name}
                    e2ePrefix={name}
                    ref={ref || registeredRef}
                    {...registerField}
                    {...rest}
                />
            </InputGroup>

            {handleErrorMessage && (
                <FormErrorMessage
                    errors={errors}
                    name={name}
                    render={({ message }) => (
                        <ErrorMessage withIcon e2ePrefix={`${name}`}>
                            {message}
                        </ErrorMessage>
                    )}
                />
            )}

            {helperText && <HelperText>{helperText}</HelperText>}
        </FormElement>
    );
});
