import React, { forwardRef, useState, useEffect } from 'react';

import { Popper } from 'components/popper/popper';
import { getInputVariant } from 'constants/formElements';
import { mergeRefs } from 'utils/fns';
import { formatPhone } from 'utils/normalizer';

import { InputPlaceholder } from './input-placeholder';
import { Input as StyledInput, InputWrapper } from './styles';


export const PhoneInputComponent = forwardRef(
    (
        {
            value,
            hidePlaceholder,
            gridArea,
            onChange,
            required,
            error,
            ...rest
        }: any,
        ref: any
    ) => {
        const [isPopperOpen, setIsPopperOpen] = useState(false);
        const [referenceElement, setReferenceElement] = useState(null);
        const [innerRefElement, setInnerRefElement] = useState(null);

        useEffect(() => {
            if (error && document.activeElement === innerRefElement) {
                // if it has focus we show the error
                setIsPopperOpen(true);
            }
        }, [innerRefElement, error]);

        return (
            <InputWrapper css={{ gridArea }} ref={setReferenceElement}>
                <StyledInput
                    ref={mergeRefs(ref, setInnerRefElement)}
                    type="text"
                    data-testid={rest?.id || rest.name}
                    id={rest?.id || rest.name}
                    value={formatPhone(value as string)}
                    onChange={(event) => {
                        onChange(
                            formatPhone(event?.currentTarget?.value || '')
                        );
                    }}
                    error={error}
                    variant={getInputVariant(error)}
                    {...rest}
                    css={{ height: hidePlaceholder ? 40 : undefined }}
                    onFocus={() => error && setIsPopperOpen(true)}
                    onBlur={() => setIsPopperOpen(false)}
                />

                {error && (
                    <Popper
                        referenceElement={referenceElement}
                        isOpen={isPopperOpen}
                        variant="error"
                    >
                        {error.message}
                    </Popper>
                )}

                {!hidePlaceholder && (
                    <InputPlaceholder
                        name={rest.name}
                        placeholder={rest.placeholder}
                        hidePlaceholder={hidePlaceholder}
                        required={required}
                    />
                )}
            </InputWrapper>
        );
    }
);
