export const BORDER_ERROR_COLORS = {
    warning: 'var(--color-light-3)',
    default: 'var(--color-red-500)',
};

export const BACKGROUND_COLOR = {
    warning: 'var(--color-light-3)',
    disabled: 'var(--color-light-5)',
    default: 'var(--color-white)',
};

export const ERROR_TYPES = {
    error: 'error',
    warning: 'warning',
    default: 'default',
};

export const getInputVariant = (error) =>
    error?.message
        ? ERROR_TYPES.error
        : ERROR_TYPES[error?.type] || ERROR_TYPES.default;
