import type { ComponentType } from 'react';
import React, { forwardRef, useEffect, useMemo, useState } from 'react';


import { useFormContext } from 'react-hook-form';
import Select, { components } from 'react-select';
import AsyncSelect from 'react-select/async';

import { Popper } from 'components/popper/popper';
import { BORDER_ERROR_COLORS, BACKGROUND_COLOR } from 'constants/formElements';
import { isEmpty, isUndefined } from 'utils';
import { mergeRefs } from 'utils/fns';
import { useTheme } from 'utils/use-theme';

import { InputPlaceholder } from './input-placeholder';
import { InputWrapper } from './styles';


import type {
    Props as SelectProps,
    ControlProps,
    CSSObjectWithLabel,
    GroupBase,
    OptionProps,
    ValueContainerProps,
    InputProps,
} from 'react-select';
import type { AsyncProps as AsyncSelectProps } from 'react-select/async';
import type { SelectOptions, SelectOption } from 'types';

type ComponentProps = {
    placeholder?: string;
    search?: boolean;
    hasBoolean?: boolean;
    onChangeSelect?: (value: string) => void;
    hidePlaceholder?: boolean;
    required?: boolean;
    options: SelectOptions<string>;
    groupedOptions?: boolean;
    error?: any;
} & Omit<SelectProps<any>, 'options'>;

/**
 * For single select, Redux Form keeps the value as a string, while React Select
 * wants the value in the form { value: "grape", label: "Grape" }
 *
 * * For multi select, Redux Form keeps the value as array of strings, while React Select
 * wants the array of values in the form [{ value: "grape", label: "Grape" }]
 */
function transformValue(value, options, isMulti, groupedOptions) {
    if ((isMulti && !value) || !value) return [];

    const filteredOptions = options.filter((option) =>
        Array.isArray(value)
            ? value.includes(option.value)
            : value === option.value
    );

    if (groupedOptions) {
        return findNestedOptions(options, value);
    }

    return isMulti ? filteredOptions : filteredOptions[0];
}

const findNestedOptions = (arrObj, value) => {
    if (typeof value === 'string') {
        value = [value];
    }

    const labels = arrObj.reduce((acc, element) => {
        if (element.hasOwnProperty('options')) {
            const nestedValues = findNestedOptions(element.options, value);
            acc = nestedValues.length ? [...acc, ...nestedValues] : [...acc];
        }
        if (element.hasOwnProperty('value') && value.includes(element.value)) {
            return [element];
        }
        return acc;
    }, []);

    return labels;
};

const mapStylesToSelect = (theme, error, hidePlaceholder = false) => {
    return {
        option: (
            base: CSSObjectWithLabel,
            props: OptionProps<SelectOptions, false, GroupBase<SelectOptions>>
        ) => ({
            ...base,
            margin: 'var(--spacing-0)',
            padding: 'var(--spacing-3)',
            fontSize: 'var(--font-size-0)',
            outline: 'none',

            ':hover':
                !props.isDisabled && !props.isSelected
                    ? {
                          color: theme.global.colors.portGore,
                          backgroundColor: theme.global.colors['gray-4'],
                      }
                    : undefined,
        }),
        menu: (base: CSSObjectWithLabel) => ({
            ...base,
            zIndex: 600, // same as theme.zIndices.dropdown
            overflow: 'hidden',
            borderRadius: 'var(--border-radius-1)',
        }),
        control: (
            base: CSSObjectWithLabel,
            props: ControlProps<SelectOptions, false, GroupBase<SelectOptions>>
        ) => ({
            ...base,
            borderRadius: 5,
            backgroundColor:
                (error && BACKGROUND_COLOR[error.type]) ||
                BACKGROUND_COLOR.default,
            minHeight: hidePlaceholder ? 40 : 60,
            ...{
                borderColor: error?.message
                    ? BORDER_ERROR_COLORS[error.type] ||
                      BORDER_ERROR_COLORS.default
                    : props.isFocused
                      ? 'var(--color-blue-500)'
                      : 'var(--color-light-1)',
            },
            ':hover ': {
                borderColor: error?.message
                    ? BORDER_ERROR_COLORS[error.type] ||
                      BORDER_ERROR_COLORS.default
                    : props.isFocused
                      ? 'var(--color-blue-500)'
                      : 'var(--color-midnight-500)',
            },
            fontSize: 'var(--font-size-0)',
            fontFamily: theme.global.fontFamily,
            fontWeight: 'var(--font-weight-4)',
            boxShadow: 'none',
        }),
        valueContainer: (
            base: CSSObjectWithLabel,
            props: ValueContainerProps<
                SelectOptions,
                false,
                GroupBase<SelectOptions>
            >
        ) => ({
            ...base,
            alignSelf:
                props.hasValue && !hidePlaceholder ? 'flex-start' : 'center',
            alignItems: 'flex-start',
            gap: 'var(--spacing-1)',
            marginTop:
                props.hasValue && !hidePlaceholder
                    ? 'var(--spacing-4)'
                    : 'var(--spacing-0)',
            padding: 'var(--spacing-2)',
        }),
        input: (base: CSSObjectWithLabel) => ({
            ...base,
            marginBlock: 'var(--spacing-0)',
            padding: 'var(--spacing-0)',
            fontSize: 12,

            '&:hasValue': {
                marginTop: 'var(--spacing-4)',
            },
        }),
        menuList: (base: CSSObjectWithLabel) => ({
            ...base,
            overflow: 'auto',
            paddingBlock: 'var(--spacing-0)',

            '::-webkit-scrollbar': {
                width: 'var(--size-2)',
                height: 'var(--size-2)',
            },
            '::-webkit-scrollbar-thumb': {
                borderRadius: 'var(--border-radius-round)',
                border: 'var(--border-width-2) solid transparent',
                backgroundColor: 'var(--color-gray-400)',
                backgroundClip: 'padding-box',
            },
            '::-webkit-scrollbar-track': {
                borderRadius: 'var(--border-radius-round)',
                border: 'var(--border-width-2) solid transparent',
                backgroundColor: 'var(--color-gray-100)',
                backgroundClip: 'padding-box',
            },
        }),
        singleValue: (base: CSSObjectWithLabel) => ({
            ...base,
            fontFamily: theme.global.fontFamily,
            fontSize: 12,
            maxWidth: 'calc(100% - 8px)',
        }),
        multiValue: (base: CSSObjectWithLabel) => ({
            ...base,
            margin: 'var(--spacing-0)',
        }),
        multiValueLabel: (base: CSSObjectWithLabel) => ({
            ...base,
            paddingBlock: 'var(--spacing-0)',
        }),
        indicatorSeparator: () => ({
            display: 'none',
        }),
        placeholder: (base: CSSObjectWithLabel) => ({
            ...base,
            display: 'none',
        }),
        noOptionsMessage: (base: CSSObjectWithLabel) => ({
            ...base,
            fontSize: 'var(--font-size-0)',
        }),
    };
};

export const returnClass = (meta, isDisabled, useClassStyle = true) => {
    if (!useClassStyle) {
        return 'default';
    }

    if (isDisabled) {
        return 'disabled';
    }

    if (meta.visited && meta.error) {
        return 'error';
    }

    if (meta.visited && meta.valid) {
        return 'valid';
    }

    return 'default';
};

const InputComponent = (
    props: InputProps<SelectOption, false, GroupBase<SelectOption>>
) => <components.Input {...props} data-testid={props.id || props.name} />;

const TextOption = (
    props: SelectOption &
        OptionProps<SelectOption, false, GroupBase<SelectOption>>
) =>
    components.Option && (
        <components.Option
            {...props}
            innerProps={Object.assign({}, props.innerProps, {
                'data-testid': props.value,
            })}
        >
            {props.label}
        </components.Option>
    );

const CUSTOM_COMPONENTS = {
    Option: TextOption,
    Input: InputComponent,
};

export const SelectV2: ComponentType<ComponentProps> = forwardRef(
    (
        {
            id,
            name,
            value,
            // eslint-disable-next-line
            onChange, //don't remove this value, it will not trigger the onChange on the form without it
            options,
            isMulti = false,
            hasBoolean = false,
            onChangeSelect,
            hidePlaceholder = false,
            menuPlacement,
            error,
            groupedOptions,
            ...rest
        }: ComponentProps,
        ref: any
    ) => {
        const {
            setValue,
            formState: { errors },
        } = useFormContext();
        const transformedValue: any = useMemo(
            () =>
                transformValue(
                    !isUndefined(value) && hasBoolean ? String(value) : value,
                    options,
                    isMulti,
                    groupedOptions
                ),
            [value, options, isMulti, groupedOptions]
        );
        // Clear the value into react select and RHF if options are updated
        // and the current value is not anymore in the options list
        useEffect(() => {
            if (transformedValue === undefined) {
                // set value to an empty string field in the form
                setValue(name, '');
            }
        }, [options]);

        return (
            <SelectUI
                ref={ref}
                components={CUSTOM_COMPONENTS}
                onChange={(selected) => {
                    const value =
                        isMulti && Array.isArray(selected)
                            ? selected.map((option) => option.value)
                            : selected
                              ? selected.value
                              : null;

                    // @ts-ignore
                    onChange && onChange(value);

                    onChangeSelect && onChangeSelect(value);
                }}
                value={transformedValue}
                defaultValue={transformedValue}
                options={options}
                menuPlacement={menuPlacement}
                isMulti={isMulti}
                {...rest}
                error={{
                    ...error,
                    type:
                        error?.type === 'warning' && rest?.required
                            ? 'error'
                            : error?.type,
                }}
                id={id || name}
                name={name}
                hidePlaceholder={hidePlaceholder}
                {...errors}
            />
        );
    }
);

type SelectUIProps = {
    gridArea?: string;
    hidePlaceholder?: boolean;
    required?: boolean;
    error?: any;
    isActiveState?: any;
    disabled?: boolean;
};

export const SelectUI = forwardRef(
    (
        {
            gridArea,
            className,
            hidePlaceholder = false,
            placeholder,
            required,
            error,
            styles,
            controlShouldRenderValue = true,
            disabled = false,
            ...rest
        }: SelectProps<any> & SelectUIProps,
        ref: any
    ) => {
        const theme = useTheme();
        const [activeState, setActiveState] = useState<boolean>(false);
        const [isPopperOpen, setIsPopperOpen] = useState(false);
        const [referenceElement, setReferenceElement] = useState(null);
        const [innerRefElement, setInnerRefElement] = useState(null);

        const hasValue =
            (controlShouldRenderValue &&
                !isEmpty(rest?.value || rest?.defaultValue)) ||
            activeState;

        useEffect(() => {
            if (error && document.activeElement === innerRefElement) {
                // if it has focus we show the error
                setIsPopperOpen(true);
            }
        }, [innerRefElement, error]);

        return (
            <InputWrapper
                className={className}
                css={{ gridArea }}
                ref={setReferenceElement}
            >
                <Select
                    ref={mergeRefs(ref, setInnerRefElement)}
                    error={error}
                    controlShouldRenderValue={controlShouldRenderValue}
                    {...rest}
                    styles={{
                        ...mapStylesToSelect(theme, error, hidePlaceholder),
                        ...styles,
                    }}
                    onInputChange={(value) => setActiveState(!!value)}
                    // Force react-select to have an empty plaholder to use our
                    placeholder=""
                    // eslint-disable-next-line
                    // @ts-ignore
                    theme={(Theme) => ({
                        ...Theme,
                        borderRadius: 0,
                        colors: {
                            ...Theme.colors,
                            primary25: theme.global.control.border.color,
                            primary: theme.global.colors.portGore,
                        },
                    })}
                    inputId={rest?.id}
                    onFocus={() => setIsPopperOpen(true)}
                    onBlur={() => setIsPopperOpen(false)}
                    isDisabled={disabled}
                />

                {error?.message && (
                    <Popper
                        referenceElement={referenceElement}
                        isOpen={isPopperOpen}
                        variant="error"
                    >
                        {error.message}
                    </Popper>
                )}
                {!hidePlaceholder && (
                    <InputPlaceholder
                        name={rest.name}
                        placeholder={placeholder as string}
                        placeholderShown={hasValue ? false : true}
                        hidePlaceholder={hidePlaceholder}
                        // Make the plaholder with ellipsis
                        css={{
                            maxWidth: `calc(100% - ${
                                hasValue ? '0px' : '36px'
                            })`,
                        }}
                        required={required}
                    />
                )}
            </InputWrapper>
        );
    }
);

export const AsyncSelectUI = forwardRef(
    (
        {
            gridArea,
            className,
            hidePlaceholder = false,
            placeholder,
            isActiveState = false,
            required,
            ...rest
        }: AsyncSelectProps<any, boolean, any> & SelectUIProps,
        ref: any
    ) => {
        const theme = useTheme();
        const [activeState, setActiveState] = useState<boolean>(false);

        const hasValue =
            !isEmpty(rest?.value || rest?.defaultValue) ||
            activeState ||
            isActiveState;

        return (
            <InputWrapper className={className} css={{ gridArea }}>
                <AsyncSelect
                    ref={ref}
                    {...rest}
                    styles={mapStylesToSelect(
                        theme,
                        rest.error,
                        hidePlaceholder
                    )}
                    onInputChange={(value, action) => {
                        // on controlled field if value otherwise it is an empty string
                        setActiveState(!!value);
                        rest?.onInputChange &&
                            rest?.onInputChange(value, action);
                    }}
                    // Show react select palceholder for the moment
                    // placeholder={placeholder as string}
                    placeholder=""
                    // eslint-disable-next-line
                    // @ts-ignore
                    theme={(Theme) => ({
                        ...Theme,
                        borderRadius: 0,
                        colors: {
                            ...Theme.colors,
                            primary25: theme.global.control.border.color,
                            primary: theme.global.colors.portGore,
                        },
                    })}
                    inputId={rest?.id}
                />
                {!hidePlaceholder && (
                    <InputPlaceholder
                        name={rest.name}
                        placeholder={placeholder as string}
                        placeholderShown={hasValue ? false : true}
                        hidePlaceholder={hidePlaceholder}
                        // Make the placeholder with ellipsis
                        css={{
                            maxWidth: `calc(100% - ${
                                hasValue ? '0px' : '36px'
                            })`,
                        }}
                        required={required}
                    />
                )}
            </InputWrapper>
        );
    }
);
