import React, { forwardRef } from 'react';

import { getDay } from 'date-fns';
import en from 'date-fns/locale/en-CA';
import fr from 'date-fns/locale/fr';
import DatePicker from 'react-datepicker';
import InputMask from 'react-input-mask';
import { Flex } from 'reflexbox/styled-components';

import 'react-datepicker/dist/react-datepicker.css';
import { Assign } from 'types/components';
import { convertUTCToLocalDate } from 'utils';
import { EMPTY_BACKEND_DATES } from 'validations/yup-extended';

import { InputPlaceholder, PlaceholderProps } from './input-placeholder';
import { Input as InputStyled, InputStylesProps, InputWrapper } from './styles';
import { ComponentProps, TextInputComponent } from './text-input';

interface DateInputComponentOwnProps extends InputStylesProps, ComponentProps {
    asISOString?: boolean;
    emptyAs0001?: boolean;
    locale?: string;
    dateFormat?: string;
    value: string;
    blockWeekends?: boolean;
}

export type DateInputComponentProps = Assign<
    React.ComponentPropsWithRef<'div'>,
    DateInputComponentOwnProps
>;

const isWeekday = (date) => {
    const day = getDay(date);
    return day !== 0 && day !== 6;
};

export const DateInputComponent: React.FC<DateInputComponentProps> = forwardRef(
    (
        {
            onChange,
            value,
            placeholder,
            error,
            dateFormat = 'dd/MM/yyyy',
            gridArea,
            asISOString = false,
            emptyAs0001 = false,
            locale = 'en',
            required,
            blockWeekends,
            ...rest
        }: DateInputComponentOwnProps,
        ref: React.Ref<HTMLInputElement>
    ) => {
        const isEmptyBackendDate = EMPTY_BACKEND_DATES.includes(value);
        return (
            <Flex
                css={{
                    width: '100%',
                    display: 'inline-flex',
                    padding: '0px',
                    gridArea,
                }}
            >
                <DatePicker
                    dateFormat={dateFormat}
                    selected={
                        value && value !== '' && !isEmptyBackendDate
                            ? convertUTCToLocalDate(value)
                            : ''
                    }
                    filterDate={blockWeekends ? isWeekday : null}
                    onChange={(date) => {
                        onChange(
                            date
                                ? asISOString
                                    ? date.toISOString()
                                    : convertUTCToLocalDate(date)
                                          .toISOString()
                                          .split('T')[0]
                                : emptyAs0001
                                ? '0001-01-01T00:00:00Z'
                                : ''
                        );
                    }}
                    customInput={
                        <DateInputMask
                            ref={ref}
                            value={convertUTCToLocalDate(value) || ''}
                            placeholder={placeholder}
                            error={error}
                            // bypass ref alter from date picker
                            innerRef={ref}
                        />
                    }
                    placeholderText={placeholder}
                    autoComplete="off"
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    locale={locale === 'fr' ? fr : en}
                    required={required}
                    {...rest}
                />
                <style global jsx>{`
                    .react-datepicker-wrapper {
                        width: 100%;
                    }
                `}</style>
            </Flex>
        );
    }
);

DateInputComponent.displayName = 'DateInputComponent';

interface DateInputFieldOwnProps extends PlaceholderProps, InputStylesProps {
    id: string;
}

export type DateInputFieldProps = Assign<
    React.ComponentPropsWithRef<'div'>,
    DateInputFieldOwnProps
>;

const DateInputField: React.FC<DateInputFieldProps> = forwardRef(
    (
        { hidePlaceholder, ...rest }: DateInputFieldOwnProps,
        ref: React.Ref<HTMLInputElement>
    ) => {
        return (
            <InputWrapper>
                <InputStyled
                    ref={ref}
                    type="text"
                    id={rest?.id || rest.name}
                    {...rest}
                />
                {!hidePlaceholder && (
                    <InputPlaceholder
                        name={rest.name}
                        placeholder={rest.placeholder}
                        hidePlaceholder={hidePlaceholder}
                        required={rest.required}
                    />
                )}
            </InputWrapper>
        );
    }
);

interface DateInputMaskOwnProps extends ComponentProps, InputStylesProps {
    innerRef: React.Ref<HTMLInputElement>;
}

const DateInputMask: React.FC<DateInputMaskOwnProps> = forwardRef(
    (
        {
            value,
            onChange,
            placeholder,
            hidePlaceholder = false,
            error,
            required,
            innerRef,
            ...rest
        }: DateInputMaskOwnProps,
        ref
    ) => {
        return (
            <InputMask
                mask="99/99/9999"
                value={value || ''}
                onChange={onChange}
                maskChar={''}
                {...rest}
                beforeMaskedValueChange={(newState) => {
                    if (!newState) {
                        return { value: '', selection: { start: 0, end: 0 } };
                    }
                    const { value: newValue, selection } = newState;
                    let newSelection = selection;

                    // We are formatting dd/mm/yyyy
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    const [day, month, _year] = newValue.split('/');
                    if (+day > 31) {
                        newSelection = { start: 0, end: 2 };
                    }

                    if (+month > 12) {
                        if (+month < 20) {
                            newSelection = {
                                start: 4,
                                end: newValue.length,
                            };
                        } else {
                            newSelection = {
                                start: 3,
                                end: newValue.length,
                            };
                        }
                    }

                    return {
                        value: newValue,
                        selection: newSelection,
                    };
                }}
            >
                {(inputProps) => (
                    <TextInputComponent
                        {...inputProps}
                        placeholder={placeholder}
                        hidePlaceholder={hidePlaceholder}
                        error={error}
                        required={required}
                        ref={innerRef}
                    />
                )}
            </InputMask>
        );
    }
);

DateInputMask.displayName = 'DateInputMask';

DateInputField.displayName = 'DateInputField';
