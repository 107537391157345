import React from 'react';

import { Box } from 'reflexbox/styled-components';

export const Label = React.forwardRef(function Label(props: any, ref: any) {
    return (
        <Box
            ref={ref}
            as="label"
            variant="label"
            {...props}
            // __themeKey="forms"
            css={{
                width: '100%',
                display: 'flex',
            }}
        />
    );
});
