import React, { forwardRef, useState } from 'react';

import css from '@styled-system/css';
import cn from 'classnames';
import { CheckBox as GrommetCheckBox } from 'grommet';
import { Box } from 'reflexbox/styled-components';

import { CheckboxIcon } from './checkbox-icon';
import styles from './checkbox.module.scss';

import type { CheckBoxProps } from 'grommet';

export const Checkbox = forwardRef(
    (
        {
            checked,
            onChange,
            ...rest
        }: CheckBoxProps & Omit<JSX.IntrinsicElements['input'], 'value'>,
        ref
    ) => {
        const [checkedState, setChecked] = useState(
            checked || rest.defaultChecked
        );

        return (
            <GrommetCheckBox
                // eslint-disable-next-line
                // @ts-ignore
                ref={ref}
                checked={checkedState}
                onChange={(event) => {
                    setChecked(event.target.checked);

                    // @see https://github.com/react-hook-form/react-hook-form/blob/master/src/logic/getInputValue.ts#L6
                    // react-hook-form can have primitive string, number boolean values but TS it's done
                    // eslint-disable-next-line
                    // @ts-ignore
                    onChange && onChange(event.target.checked);
                }}
                {...rest}
            />
        );
    }
);

type Props = any;

export const CheckboxComponent = forwardRef(
    ({ className, children, ...rest }: Props, ref: any) => (
        <Box
            className={cn([`${styles.checkbox}`], {
                [`${styles.checkbox} ${styles['checkbox--square']}`]:
                    !rest.disabled && rest.squareCheckbox,
                [`${styles.checkbox} ${styles['checkbox--disabled']}`]:
                    rest.disabled && !rest.squareCheckbox,
                [`${styles.checkbox} ${styles['checkbox--square']} ${styles['checkbox--disabled']}`]:
                    rest.disabled && rest.squareCheckbox,
                [`${styles['checkbox--hidden']}`]: rest.hidden,
            })}
        >
            <Box
                ref={ref}
                as="input"
                // @ts-ignore
                type="checkbox"
                {...rest}
                css={css({
                    position: 'absolute',
                    opacity: 0,
                    zIndex: -1,
                    width: 1,
                    height: 1,
                    overflow: 'hidden',
                })}
            />
            <CheckboxIcon
                className={className}
                squareCheckbox={rest.squareCheckbox}
                isCheckboxChecked={rest.checked}
            />
            {children}
        </Box>
    )
);
